
.mainBanner {
    height: 100vh;
    margin-top: 15vh;
    width: 80%;
    margin-left: auto;
    margin-right: auto;
}

.mainTopBanner {
    display: grid;
    grid-template-columns: 30% 40% 30%;
    width: 100%;
}
.homeImageMain {
    width: 50vw;
    height: 46vh;
}

.mainCenterBanner {
    border-right: 2px solid #d5af80;
    border-left: 2px solid #d5af80;
    min-height: 50px;
    margin-top: -10px;
}

.mainBottomBanner {
    border-right: 2px solid #d5af80;
    border-left: 2px solid #d5af80;
    border-bottom: 2px solid #d5af80;
    min-height: 50px;
    padding-top: 20px;
}

.topBannerLeft {
    border-top: 2px solid #d5af80;
    border-left: 2px solid #d5af80;
}

.topBannerCenter {
    margin-top: -125px;
    z-index: 2;
}

.topBannerRight {
    border-top: 2px solid #d5af80;
    border-right: 2px solid #d5af80;
}

.centerBannerTop {
    font-size: 58px;
    color: #d5af80;
    width: 100%;
    letter-spacing: 2px;
    font-kerning: auto;
}

.centerBannerMid {
    font-size: 96px;
    color: #d5af80;
    padding-top: 30px;
    width: 100%;
    letter-spacing: 2px;
    font-kerning: auto;
}

.centerBannerBottom {
    font-size: 28px;
    color: #d5af80;
    letter-spacing: 2px;
    font-weight: 600;
    padding-top: 50px;
    font-kerning: auto;
}

.lowerBannerTop {
    font-size: 18px;
    color: #d5af80;
    letter-spacing: 2px;
    font-weight: 600;
    width: 100%;
}

.lowerBannerBottom {
    font-size: 18px;
    color: #d5af80;
    letter-spacing: 2px;
    font-weight: 600;
    width: 100%;
}

.textSection {
    padding-top: 10px;
    padding-bottom: 100px;
    font-kerning: auto;
}

.textHeader {
    font-size: 48px;
    border-bottom: 2px solid #d5af80;
    padding-bottom: 20px;
}

.textPara {
    padding: 40px 0px;
    font-size: 22px;
    line-height: 1.6;
    width: 80%;
    margin-left: auto;
    margin-right: auto;
}


@media all and (max-width: 500px) {
    .mainBanner {
        height: 90vh;
        margin-top: 15vh;
        width: 80%;
        margin-left: auto;
        margin-right: auto;
    }

    .mainTopBanner {
        display: grid;
        grid-template-columns: 30% 40% 30%;
        width: 100%;
    }

    .mainCenterBanner {
        border-right: 2px solid #d5af80;
        border-left: 2px solid #d5af80;
        min-height: 50px;
        margin-top: -10px;
    }

    .mainBottomBanner {
        border-right: 2px solid #d5af80;
        border-left: 2px solid #d5af80;
        border-bottom: 2px solid #d5af80;
        min-height: 50px;
        padding-top: 20px;
    }

    .homeImageMain {
        width: 40vw;
        height: 20vh;
    }

    .topBannerLeft {
        border-top: 2px solid #d5af80;
        border-left: 2px solid #d5af80;
    }

    .topBannerCenter {
        margin-top: -75px;
        z-index: 2;
    }

    .topBannerRight {
        border-top: 2px solid #d5af80;
        border-right: 2px solid #d5af80;
    }

    .centerBannerTop {
        font-size: 22px;
        color: #d5af80;
        width: 100%;
        letter-spacing: 2px;
        font-kerning: auto;
    }

    .centerBannerMid {
        font-size: 30px;
        color: #d5af80;
        padding-top: 50px;
        width: 100%;
        letter-spacing: 2px;
        font-kerning: auto;
        font-weight: 800;
    }

    .centerBannerBottom {
        font-size: 20px;
        color: #d5af80;
        letter-spacing: 2px;
        font-weight: 600;
        padding-top: 100px;
        font-kerning: auto;
    }

    .lowerBannerTop {
        font-size: 14px;
        color: #d5af80;
        letter-spacing: 2px;
        font-weight: 600;
        width: 100%;
        padding-top: 100px;
    }

    .lowerBannerBottom {
        font-size: 14px;
        color: #d5af80;
        letter-spacing: 2px;
        font-weight: 600;
        width: 100%;
    }

    .textSection {
        padding-top: 10px;
        padding-bottom: 40px;
        font-kerning: auto;
    }

    .textHeader {
        font-size: 20px;
        border-bottom: 2px solid #d5af80;
        padding-bottom: 20px;
    }

    .textPara {
        padding: 40px 0px;
        font-size: 16px;
        line-height: 1.6;
        width: 80%;
        margin-left: auto;
        margin-right: auto;
    }
}
