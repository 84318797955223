.footer {
    padding: 100px 100px 20px 100px;
    background-color: #2f4d4b;
}

.footerContainer {
    border-top: 1px solid #d5af80;
    padding: 100px 40px;
}

.footerLogoTextMain {
    font-size: 54px;
    color: #d5af80;
}
.footerLogoTextSub {
    padding-left: 350px;
    font-size: 22px;
    color: #d5af80;
    border-bottom: 1px solid #d5af80;
}
.footerLogoTextSub{
    padding-bottom: 60px;
}
.footerInfoMain {
    display: grid;
    grid-template-columns: 50% 50%;
    padding-left: 50px;
    padding-right: 50px;
    padding-bottom: 20px
}

.footerInfo {
    display: grid;
    grid-template-columns: 50% 50%;
    border-right: 1px solid #d5af80;
}
.footerInfoDetails {
    color: #d5af80;
    text-align: left
}

.footerInfoDetails a {
    color: #d5af80;
    text-decoration: none;
    font-size: 18px;
    line-height: 2;

}

.contactInfo {
    display: grid;
    grid-template-columns: 50% 50%;
}

.contactInfoSub {
    color: #d5af80;
    text-align: left;
    padding-left: 60px;

}

.contactHeader{
    font-size: 18px;
    padding-top: 10px;
}

.contactCard{
    padding: 10px 30px;
    width: 100%;
    font-size: 18px;
}

.copyRight {
    width: 100%;
    color: #d5af80;
    padding-top: 100px;
    border-top: 1px solid #d5af80;
}

@media all and (max-width: 500px) {
    .footer {
        padding: 30px;
        background-color: #2f4d4b;
    }

    .footerContainer {
        border-top: 1px solid #d5af80;
        padding: 30px 10px;
    }

    .footerLogoTextMain {
        font-size: 28px;
        color: #d5af80;
    }

    .footerLogoTextSub {
        padding-left: 150px;
        font-size: 18px;
        color: #d5af80;
        border-bottom: 1px solid #d5af80;
        
    }

    .footerLogoTextSub {
        padding-bottom: 30px;
    }

    .footerInfoMain {
        display: grid;
        grid-template-columns: 100%;
        padding-left: 50px;
        padding-right: 50px;
    }

    .footerInfo {
        display: grid;
        grid-template-columns: 100%;
        border-bottom: 1px solid #d5af80;
        border-right: 0px;
    }

    .footerInfoDetails {
        color: #d5af80;
        text-align: left
    }

    .footerInfoDetails a {
        color: #d5af80;
        text-decoration: none;
        font-size: 18px;
        line-height: 2;
    }

    .contactInfo {
        display: grid;
        grid-template-columns: 100%;
        padding-top: 40px;
    }

    .contactInfoSub {
        color: #d5af80;
        text-align: left;
        padding-left: 10px;
    }

    .contactHeader {
        font-size: 18px;
        padding-top: 10px;
    }

    .contactCard {
        padding: 10px;
        width: 100%;
        font-size: 18px;
    }
    .copyRight {
        width: 100%;
        color: #d5af80;
        padding-top: 30px;
        border-top: 1px solid #d5af80;
    }

}