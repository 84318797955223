

.headerContainer {
    display: grid;
    grid-template-columns: 65% 35%;
}

.logoContainer {
    text-align: left;
    padding-left: 20px;
    font-size: 24px;
    color: #d5af80;
    font-weight: 600;
    font-family: cursive;
}

.rightMenuContainer {
    color: #d5af80;
    display: grid;
    grid-template-columns: 70% 30%;
}

.contactContainer {
    color: #d5af80;
    display: flex;
    padding-right: 20px;
    text-align: right;
    padding-top: 15px;
}

.phoneSymbolContainer {
    font-size: 32px;
    font-weight: 800;
    margin-top: -10px;
}

.contactContainer {
    font-size: 22px;
    padding-left: 20px;
    padding-top: 5px;
    padding-right: 10px;
}

.menuContainer {
    padding-right: 70px;
}

.menuParent {
    font-size: 20px;
    padding: 5px;
    border: 1px solid #d5af80;
    cursor: pointer;
}


@media all and (max-width: 500px) {

    .headerContainer {
        display: grid;
        grid-template-columns: 40% 60%;
    }

    .logoContainer {
        text-align: left;
        padding-left: 20px;
        font-size: 16px;
        color: #d5af80;
        font-weight: 600;
        font-family: cursive;
        padding-top: 2px;
    }

    .rightMenuContainer {
        color: #d5af80;
        display: grid;
        grid-template-columns: 80% 20%;
    }

    .contactContainer {
        color: #d5af80;
        display: flex;
        padding-right: 5px;
        text-align: right;
        padding-top: 15px;
    }

    .phoneSymbolContainer {
        font-size: 16px;
        font-weight: 800;
        margin-top: -4px;
    }

    .contactContainer {
        font-size: 14px;
        padding-left: 5px;
        padding-top: 2px;
        padding-right: 5px;
    }

    .menuContainer {
        padding-right: 5px;
    }

    .menuParent {
        font-size: 14px;
        padding: 2px;
        border: 1px solid #d5af80;
        cursor: pointer;
    }
}
